<template>
  <v-data-table :headers="headers" :items="datas" mobile-breakpoint="0" class="table-tax" :items-per-page="25"
    :hide-default-footer="true">
    <template v-slot:item="row">
      <tr  class="tr-stretch">
        <td valign="top" class="pt-2">
          <v-icon small @click="editItem(row.item)" style="color:#00a4b3;"> mdi-pencil </v-icon>
        </td>
         <td valign="top" class="pt-2 tb-wide">{{ row.item.tax_number }}</td>
        <td valign="top" class="pt-2 tb-wide">{{ row.item.tax_office }}</td>
        <td valign="top" class="pt-2 tb-wide">{{ formatDate(row.item.registrar_date) }}</td>
      </tr>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("page.profile.tax_info") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template  v-if="datas.length == 0" v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 button"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("add") }}
            </v-btn>
          </template>
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{ $t("page.profile.add_new_bank") }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>

                 <v-text-field
                  ref="tax_number"
                  v-model="tax_number"
                  :rules="[() => !!tax_number || $t('is_required')]"
                  :label="$t('page.profile.tax_number')"
                  :placeholder="$t('page.profile.tax_number')"
                  class="fontSet"
                ></v-text-field>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="registrar_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-0 fontSet"
                        v-model="dateFormattedAdd"
                        :label="$t('page.profile.registrar_date')"
                        :rules="[
                          (v) =>
                            !!v ||
                            `${$t('page.profile.registrar_date')} ${$t('is_required')}`,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="DD-MM-YYYY format"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    :locale="$i18n.locale"
                      v-model="registrar_date"
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(registrar_date)"
                      >
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                <v-text-field
                  ref="tax_office"
                  v-model="tax_office"
                  :rules="[() => !!tax_office || $t('is_required')]"
                  :label="$t('page.profile.tax_office')"
                  :placeholder="$t('page.profile.tax_office')"
                  class="fontSet uppercase-input"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="close"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        
        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{
                $t("page.profile.edit_tax_info")
              }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                 <v-text-field
                  ref="tax_number"
                  v-model="editedItem.tax_number"
                  :rules="[() => !!editedItem.tax_number || $t('is_required')]"
                  :error-messages="errorMessages"
                  :label="$t('page.profile.tax_number')"
                  :placeholder="$t('page.profile.tax_number')"
                  class="mt-0 fontSet"
                ></v-text-field>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="editedItem.registrar_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="fontSet"
                        v-model="dateFormatted"
                        :label="$t('page.profile.registrar_date')"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('page.profile.registrar_date')} ${$t('is_required')}`,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="DD-MM-YYYY format"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker :locale="$i18n.locale" v-model="editedItem.registrar_date" no-title>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(editedItem.registrar_date)"
                      >
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                <v-text-field
                  ref="tax_office"
                  v-model="editedItem.tax_office"
                  :rules="[() => !!editedItem.tax_office || $t('is_required')]"
                  :error-messages="errorMessages"
                  :label="$t('page.profile.tax_office')"
                  :placeholder="$t('page.profile.tax_office')"
                  class="mt-0 fontSet uppercase-input"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeEdit"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import moment from "moment";

export default {
  data: () => ({
    dialog: false,
     modalAdd: false,
      modal: false,
    modal_issue: false,
    dialogEdit: false,
    formHasError: false,
    headers: [],
    datas: [],
     tax_office: null,
    tax_number: null,
    registrar_date: null,
    editedIndex: -1,
    errorMessages: "",
    editedItem: {
      tax_number: "",
      tax_office: "",
      registrar_date: "",
    },
    defaultItem: {
      tax_number: "",
      tax_office: "",
      registrar_date: "",
    },
    rules: {
      required: value => !!value || "This field is required.",
    },
  }),
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.individual_tax_no"),
        sortable: false,
        align: "center",
        value: "tax_number",
      },
      {
        text: this.$t("page.profile.tax_office"),
        value: "tax_office",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.registrar_date"),
        value: "registrar_date",
        sortable: false,
        align: "center",
      },
     
    ];
    if (this.dataProfile) {
      this.getDataTax();
    }
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.editedItem.registrar_date);
    },
     dateFormattedAdd() {
      return this.formatDate(this.registrar_date);
    },
    form() {
      return {
        tax_number: this.editedItem.tax_number,
        tax_office: this.editedItem.tax_office,
        registrar_date: this.editedItem.registrar_date,
      };
    },
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY").toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    close() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate() == true) {
        // reference instance ke variabel karena tidak akan terbaca pada proses async
        var vm = this;

        // Jika ada edit item, karena jika diedit index akan selalu lebih dari -1
        if (this.editedIndex > -1) {
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          try {
           let form = {
              tax_number:
                typeof this.editedItem.tax_number !== "undefined"
                  ? this.editedItem.tax_number.toString().toUpperCase()
                  : "",
              tax_office:
                typeof this.editedItem.tax_office !== "undefined"
                  ? this.editedItem.tax_office.toString().toUpperCase()
                  : "",
              registrar_date:
                typeof this.editedItem.registrar_date !== "undefined"
                  ? this.editedItem.registrar_date.toString().toUpperCase()
                  : "",
            };

            // Begin api request
            (async function () {
              const res = await API.put(
                `${process.env.VUE_APP_API_TRUST}doctor/update-tax`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("field.data_edit_success"));
                setTimeout(function () {
                  vm.getDataTax();
                }, 200);
              } else if (res.statusCode == 403) {
                vm.toast("error", res.message);
                // Object.keys(res.errors).forEach(element => {
                //   vm.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
              } else {
                vm.$swal({
                  icon: "error",
                  text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            // console.log(error);
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.closeEdit();
        }

        // insert Data
        else {
          try {
            let form = {
               tax_number:
                typeof this.tax_number !== "undefined"
                  ? this.tax_number.toString().toUpperCase()
                  : "",
              tax_office:
                typeof this.tax_office !== "undefined"
                  ? this.tax_office.toString().toUpperCase()
                  : "",
              registrar_date:
                typeof this.registrar_date !== "undefined"
                  ? this.registrar_date.toString().toUpperCase()
                  : "",
            };

            // Begin api request
            (async function () {
              const res = await API.put(
                `${process.env.VUE_APP_API_TRUST}doctor/update-tax`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("field.data_edit_success"));
                setTimeout(function () {
                  vm.getDataTax();
                }, 200);
              } else if (res.statusCode == 403) {
                vm.toast("error", res.message);
                // Object.keys(res.errors).forEach(element => {
                //   vm.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
              } else {
                vm.$swal({
                  icon: "error",
                  text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            console.log(error);
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.close();
        }
      }
    },

    async getDataTax() {
      // console.log(`${Auth.getToken()}`);
      try {
        

        // Check result exist
        if (this.dataProfile.person.practitioner.tax.length > 0) {
          var new_data = [];
         this.dataProfile.person.practitioner.tax.map(function (e) {
            new_data.push({
              tax_number:
                typeof e.tax_number !== "undefined" ? e.tax_number : "",
              tax_office:
                typeof e.tax_office !== "undefined" ? e.tax_office : "",
              registrar_date:
                typeof e.registrar_date !== "undefined"
                  ? e.registrar_date
                  : "",
            
            });
          });

          this.datas = new_data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.table-tax thead {
      background: #ddd !important;
      font-weight: bold;
      color: #000 !important;
  }
  .table-tax thead tr th{
      height: 38px !important;
  }
  .table-tax tbody tr td{
      font-size: 12px !important;
      height: 35px !important;
  }
  .table-tax tbody tr:hover{
      background-color: transparent !important;
  }
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.v-btn {
  display: flex;
  justify-content: flex-end;
}

.v-data-table td {
  font-size: 12px !important;
}
.text-custom {
  font-size: 12px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 12px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  height: 30px !important;
  min-width: 100px !important;
  justify-content: center;
}
</style>
