<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    mobile-breakpoint="0"
    class="table-hospital"
    :items-per-page="25"
    :hide-default-footer="true"
  >
    <template v-slot:item="row">
      <tr class="sticky-popup-hht">
        <!-- <td>
          <v-icon small class="mr-2" @click="editItem(row.item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(row.item)"> mdi-delete </v-icon>    
        </td> -->
        <td class="text-custom tb-wide sticky-htc1">{{ row.item.name }}</td>
        <td class="text-custom ">{{ row.item.license_no }}</td>
        <td class="text-custom">{{ row.item.issued_at }}</td>
        <td class="text-custom tb-wide">{{ formatDate(row.item.start_date) }}</td>
        <td class="text-custom tb-wide">{{ formatDate(row.item.end_date) }}</td>
        <td class="text-custom tb-wide">{{ row.item.address }}</td>
        
      </tr>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{
          $t("page.profile.hospital_info")
        }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Add
            </v-btn>
          </template> -->
          <v-card>
            <v-form ref="form" lazy-validation>
              <v-card-title>{{
                $t("page.profile.add_new_hospital")
              }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  v-model="name"
                  :rules="[v => !!v || $t('this_field_required')]"
                  :label="$t('page.profile.hospital_name')"
                  :placeholder="$t('page.profile.hospital_name')"
                ></v-text-field>
                <v-text-field
                  v-model="city"
                  :rules="[v => !!v || $t('this_field_required')]"
                  :label="$t('field.city')"
                  :placeholder="$t('field.city')"
                ></v-text-field>
                <v-text-field
                  v-model="state"
                  :rules="[v => !!v || $t('this_field_required')]"
                  :label="$t('field.state')"
                  :placeholder="$t('field.state')"
                ></v-text-field>
                <v-autocomplete
                  v-model="country"
                  :rules="[v => !!v || $t('this_field_required')]"
                  :items="countries"
                  :label="$t('field.country')"
                  :placeholder="`${$t('select')} ${$t('field.country')}`"
                ></v-autocomplete>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="close"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-card>
            <v-form ref="form" lazy-validation>
              <v-card-title>{{
                $t("page.profile.edit_education")
              }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[
                    () => !!editedItem.name || $t('this_field_required'),
                  ]"
                  label="City"
                  placeholder="City"
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.city"
                  :rules="[
                    () => !!editedItem.city || $t('this_field_required'),
                  ]"
                  label="City"
                  placeholder="City"
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.state"
                  :rules="[
                    () => !!editedItem.state || $t('this_field_required'),
                  ]"
                  label="State/Province/Region"
                  placeholder="State"
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.country"
                  :rules="[
                    () => !!editedItem.country || $t('this_field_required'),
                  ]"
                  label="Country"
                  placeholder="Country"
                ></v-text-field>
                <!-- <v-autocomplete
                  ref="country"
                  v-model="editedItem.country"
                  :rules="[() => !!editedItem.country || $t('this_field_required')]"
                  :items="countries"
                  label="Country"
                  placeholder="Select..."
                ></v-autocomplete> -->
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeEdit"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="update">
                  {{ $t("update") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.u_sure_want_delete") }}</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">{{ $t("cancel") }}</v-btn>
              <v-btn color="#00a4b3" text @click="deleteItemConfirm">{{
                $t("oke")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import moment from "moment";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    formHasError: false,
    headers: [],
    datas: [],
    countries: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antigua &amp; Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bosnia &amp; Herzegovina",
      "Botswana",
      "Brazil",
      "British Virgin Islands",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Cape Verde",
      "Cayman Islands",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Congo",
      "Cook Islands",
      "Costa Rica",
      "Cote D Ivoire",
      "Croatia",
      "Cruise Ship",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Polynesia",
      "French West Indies",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kuwait",
      "Kyrgyz Republic",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macau",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Nepal",
      "Netherlands",
      "Netherlands Antilles",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Reunion",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Pierre &amp; Miquelon",
      "Samoa",
      "San Marino",
      "Satellite",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "South Africa",
      "South Korea",
      "Spain",
      "Sri Lanka",
      "St Kitts &amp; Nevis",
      "St Lucia",
      "St Vincent",
      "St. Lucia",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      `Timor L'Este`,
      "Togo",
      "Tonga",
      "Trinidad &amp; Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks &amp; Caicos",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Venezuela",
      "Vietnam",
      "Virgin Islands (US)",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    editedIndex: -1,
    name: null,
    city: null,
    state: null,
    country: null,
    start_date: null,
    end_date: null,
    errorMessages: "",
    editedItem: {
      name: "",
      city: "",
      state: "",
      country: "",
      start_date: "",
      end_date: "",
    },
    defaultItem: {
      name: "",
      city: "",
      state: "",
      country: "",
      start_date: "",
      end_date: "",
    },
  }),

  computed: {
    form() {
      return {
        name: this.name,
        city: this.city,
        state: this.state,
        country: this.country,
        start_date: this.start_date,
        end_date: this.end_date,
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    name() {
      this.errorMessages = "";
    },
  },

  created() {
    this.initData();
    this.headers = [
      // {
      //   text: this.$t("page.profile.actions"),
      //   value: "actions",
      //   sortable: false,
      //   align: "center",
      // },
      {
        text: this.$t("page.profile.hospital_name"),
        align: "center",
        sortable: false,
        value: "name",
        class: "sticky-hht1",
      },
      {
        text: this.$t("page.profile.license_no"),
        value: "city",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.issued_at"),
        value: "state",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.license_issued_date"),
        value: "start_date",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.license_expired_date"),
        value: "end_date",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.address"),
        value: "country",
        sortable: false,
        align: "center",
      },
    ];
  },

  methods: {
    async initData() {
      try {
        // `${process.env.VUE_APP_API_TRUST}profile`,
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}doctor/get-workplaces`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        console.log(res)
        var tempData = [];
        for (const item in res.results) {
            let data = res.results[item];
            let format = {
              name:
                typeof data.health_facility !== "undefined" ? data.health_facility : "-",
              license_no: typeof data.license_number !== "undefined" ? data.license_number : "-",
              issued_at: typeof data.license_issuer !== "undefined" ? data.license_issuer : "-",
              start_date: typeof data.license_date !== "undefined" ? data.license_date : "-",
              end_date: typeof data.license_expired !== "undefined" ? data.license_expired : "-",
              address: data.address !== null ? data.address + ", " + data.city + ", " + data.district + ", " + data.subdistrict + ", " + data.province + ", " + data.postal_code + ", " + data.country : "-",
            };
            tempData.push(format);
        }
       
        // for (const item in res.person.hospital_info){
        // let data = res.person.hospital_info;
        // let format = {
        //   name:
        //     typeof data.hospital_name !== "undefined" ? data.hospital_name : "",
        //   city:
        //     typeof data.hospital_city !== "undefined" ? data.hospital_city : "",
        //   state:
        //     typeof data.hospital_state !== "undefined"
        //       ? data.hospital_state
        //       : "",
        //   country:
        //     typeof data.hospital_country !== "undefined"
        //       ? data.hospital_country
        //       : "",
        //   start_date:
        //     typeof data.start_date !== "undefined" ? data.start_date : "",
        //   end_date: typeof data.end_date !== "undefined" ? data.end_date : "",
        // };
        // tempData.push(format);
        // }

        this.datas = tempData;
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let newData = JSON.parse(JSON.stringify(this.datas));
      newData.splice(this.editedIndex, 1);

      let loop_edukasi = [];
      for (var o in newData) {
        loop_edukasi.push(newData[o]);
      }

      let edukasi = {};
      edukasi["education"] = loop_edukasi;
      let formData = [];
      formData.push(edukasi);

      var _this = this;
      await API.put(
        `${process.env.VUE_APP_API_TRUST}education/update`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        formData
      )
        .then(res => {
          if (res.statusCode == 201) {
            this.toast("success", this.$t("field.data_delete_success"));
            _this.initEducation();
            _this.closeDelete();
          } else if (res.statusCode == 400) {
            this.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   this.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
              //text: this.$t("error_try_again_latter"),
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            text: this.$t("error_try_again_latter"),
          });
        });
    },

    close() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      /* eslint-disable no-unused-vars */
      if (this.$refs.form.validate() == true) {
        var form = {
          name: this.hospital_name,
          city: this.city,
          state: this.state,
          country: this.country.toUpperCase(),
        };
        this.initEducation();

        let newData = JSON.parse(JSON.stringify(this.datas));
        newData.push(form);

        let loop_edukasi = [];
        for (var o in newData) {
          loop_edukasi.push(newData[o]);
        }

        let edukasi = {};
        edukasi["education"] = loop_edukasi;
        let formData = [];
        formData.push(edukasi);

        var _this = this;
        await API.put(
          `${process.env.VUE_APP_API_TRUST}education/update`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          formData
        )
          .then(res => {
            if (res.statusCode == 201) {
              this.toast("success", this.$t("field.data_add_success"));
              _this.initEducation();
              _this.close();
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
            }
          })
          .catch(er => {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          });
      }
      // if (this.editedIndex > -1) {
      //   Object.assign(this.datas[this.editedIndex], this.editedItem);
      // } else {
      //   this.datas.push(this.editedItem);
      // }
      // this.close();
      /* eslint-disable no-unused-vars */
    },
    async update() {
      /* eslint-disable no-unused-vars */
      if (this.$refs.form.validate() == true) {
        let updatedData = Object.assign({}, this.datas);
        updatedData[this.editedIndex] = this.editedItem;

        let loop_edukasi = [];
        for (var o in updatedData) {
          loop_edukasi.push(updatedData[o]);
        }

        let edukasi = {};
        edukasi["education"] = loop_edukasi;
        let formData = [];
        formData.push(edukasi);

        var _this = this;
        await API.put(
          `${process.env.VUE_APP_API_TRUST}education/update`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          formData
        )
          .then(res => {
            if (res.statusCode == 201) {
              this.toast("success", this.$t("field.data_edit_success"));
              _this.initEducation();
              _this.closeEdit();
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
            }
          })
          .catch(er => {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          });
      }

      /* eslint-disable no-unused-vars */
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formatDate(date) {
      // console.log(date);
      if (!date) return null;
      // const MMM = {
      //   "01": "Jan",
      //   "02": "Feb",
      //   "03": "Mar",
      //   "04": "Apr",
      //   "05": "May",
      //   "06": "June",
      //   "07": "July",
      //   "08": "Aug",
      //   "09": "Sept",
      //   "10": "Oct",
      //   "11": "Nov",
      //   "12": "Dec",
      // };
      // const [year, month, day] = date.split("-");
      // let dates = `${day} ${MMM[month]} ${year}`;
      // console.log(day);
      // console.log(year);
      // console.log(month);
      // console.log(dates.toUpperCase());
      // console.log('   ');
      // console.log('   ');
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY").toUpperCase();
    },
  },
};
</script>
<style>
  @media screen and (min-width: 1023px) {
  .sticky-htc1{
    position: sticky!important;
    position: -webkit-sticky!important;
    left: 0;
    background: #fff;
  }
  .sticky-htc2{
    position: sticky!important;
    position: -webkit-sticky!important;
    left: auto !important;
    background: #fff;
  }
  .sticky-hht1{
    position: sticky!important;
    position: -webkit-sticky!important;
    left: 0;
    background: #ddd;
  }
  .sticky-hht2{
    position: sticky!important;
    position: -webkit-sticky!important;
    left: auto !important;
    background: #ddd;
  }
}
 .table-hospital thead {
      background: #ddd !important;
      font-weight: bold;
      color: #000 !important;
  }
  .table-hospital thead tr th{
      height: 38px !important;
      white-space: nowrap;
      text-align: center;
  }
  .table-hospital tbody tr td{
      font-size: 12px !important;
      height: 35px !important;
      white-space: nowrap;
  }
  .table-hospital tbody tr:hover{
      background-color: transparent !important;
  }
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
@media screen and (min-width: 1023px) {
  .sticky-popup-hht:hover{
    .sticky-popup-htc1{
      background: #eee !important;
    }
    .sticky-popup-htc2{
      background: #eee !important;
    }
    .v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child{
      background: #eee !important;
    }
  }
}  
.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.a {
  color: #000;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 12px !important;
}
</style>
