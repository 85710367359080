<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    mobile-breakpoint="0"
    class="table-license"
    :items-per-page="25"
    :hide-default-footer="true"
  >
    <template v-slot:item="row">
      <tr class="tr-stretch">
        <td>
          <v-icon
            small
            class="mr-2"
            @click="editItem(row.item)"
            style="color: #00a4b3"
          >
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(row.item)" style="color: #00a4b3">
            mdi-delete
          </v-icon>
        </td>
        <td class="text-custom tb-wide uppercase-table">
          {{ row.item.license_no }}
        </td>
        <td class="text-custom">{{ row.item.country }}</td>
        <td class="text-custom tb-wide uppercase-table">
          {{ row.item.specialist }}
        </td>
        <td class="text-custom">{{ row.item.type_code }}</td>
        <td class="text-custom tb-wide">
          {{ formatDate(row.item.issued_at) }}
        </td>
        <td class="text-custom tb-wide">
          {{ formatDate(row.item.expired_at) }}
        </td>
      </tr>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("page.profile.license_info") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="#00A4B3" dark class="mb-2" v-bind="attrs" v-on="on">
              {{ $t("add") }}
            </v-btn>
          </template> -->
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{
                $t("page.profile.add_new_license")
              }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  ref="license_no"
                  class="uppercase-input"
                  v-model="license_no"
                  :rules="[v => !!v || $t('is_required')]"
                  :error-messages="errorMessages"
                  :label="$t('page.profile.license_no')"
                  :placeholder="$t('page.profile.license_no')"
                ></v-text-field>
                <v-autocomplete
                  ref="country"
                  v-model="country"
                  :rules="[v => !!v || $t('is_required')]"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.country')"
                  :placeholder="$t('field.country')"
                ></v-autocomplete>
                <v-text-field
                  ref="specialist"
                  class="uppercase-input"
                  v-model="specialist"
                  :rules="[v => !!v || $t('is_required')]"
                  :label="$t('page.profile.specialist')"
                  :placeholder="$t('page.profile.specialist')"
                ></v-text-field>
                <v-autocomplete
                  ref="type_code"
                  v-model="type_code"
                  :rules="[v => !!v || $t('is_required')]"
                  :items="type_code_list"
                  item-text="name"
                  item-value="id"
                  :label="$t('page.profile.type')"
                  :placeholder="`${$t('select')} ${$t('page.profile.type')}`"
                ></v-autocomplete>
                <v-dialog
                  ref="dialog"
                  v-model="modal_issue"
                  :return-value.sync="issued_at"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0"
                      v-model="dateFormatted_issue"
                      :label="$t('page.profile.issued_at')"
                      :rules="[v => !!v || $t('is_required')]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :hint="$t('page.profile.license_issued_date')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="issued_at"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal_issue = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(issued_at)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="dialog2"
                  v-model="modal_expiry"
                  :return-value.sync="expired_at"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0"
                      v-model="dateFormatted_expiry"
                      :label="$t('page.profile.expired_at')"
                      :rules="[v => !!v || $t('is_required')]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :hint="$t('page.profile.license_expired_date')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="expired_at"
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal_expiry = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog2.save(expired_at)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="close"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{ $t("page.profile.edit_license") }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  ref="license_no"
                  class="uppercase-input"
                  v-model="editedItem.license_no"
                  :rules="[v => !!v || $t('is_required')]"
                  :error-messages="errorMessages"
                  :label="$t('page.profile.license_no')"
                  :placeholder="$t('page.profile.license_no')"
                ></v-text-field>
                <v-autocomplete
                  ref="country"
                  v-model="editedItem.country_id"
                  :rules="[v => !!v || $t('is_required')]"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  :label="$t('field.country')"
                  :placeholder="`${$t('select')} ${$t('field.country')}`"
                ></v-autocomplete>
                <v-text-field
                  ref="specialist"
                  class="uppercase-input"
                  v-model="editedItem.specialist"
                  :rules="[v => !!v || $t('is_required')]"
                  :label="$t('page.profile.specialist')"
                  :placeholder="$t('page.profile.specialist')"
                ></v-text-field>
                <v-autocomplete
                  ref="type_code"
                  v-model="editedItem.type_code"
                  :rules="[v => !!v || $t('is_required')]"
                  :items="type_code_list"
                  item-text="name"
                  item-value="id"
                  :label="$t('page.profile.type')"
                  :placeholder="`${$t('select')} ${$t('page.profile.type')}`"
                ></v-autocomplete>
                <v-dialog
                  ref="dialog"
                  v-model="modal_issue"
                  :return-value.sync="editedItem.issued_at"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0"
                      v-model="dateFormatted_issue_edit"
                      :label="$t('page.profile.issued_at')"
                      :rules="[v => !!v || $t('is_required')]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :hint="$t('page.profile.license_issued_date')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="editedItem.issued_at_iso"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal_issue = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(editedItem.issued_at_iso)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="dialog2"
                  v-model="modal_expiry"
                  :return-value.sync="editedItem.expired_at"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mt-0"
                      v-model="dateFormatted_expiry_edit"
                      :label="$t('page.profile.expired_at')"
                      :rules="[v => !!v || $t('is_required')]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :hint="$t('page.profile.license_expired_date')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :locale="$i18n.locale"
                    v-model="editedItem.expired_at_iso"
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal_expiry = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog2.save(editedItem.expired_at_iso)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeEdit"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="update">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.u_sure_want_delete") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">{{ $t("cancel") }}</v-btn>
              <v-btn color="#00a4b3" text @click="deleteItemConfirm">{{
                $t("oke")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import moment from "moment";
export default {
  data: () => ({
    modal_issue: false,
    modal_expiry: false,
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    formHasError: false,
    headers: [],
    datas: [],
    countries: [],
    type_code_list: [{ name: "STR/SIP", value: "STR/SIP" }],
    editedIndex: -1,
    license_no: null,
    country: 102,
    id: null,
    specialist: null,
    expired_at: null,
    issued_at: null,
    type_code: "STR/SIP",
    errorMessages: "",
    editedItem: {
      id: "",
      license_no: "",
      country: "",
      specialist: "",
      type_code: "STR/SIP",
      expired_at: "",
      issued_at: "",
      expired_at_iso: "",
      issued_at_iso: "",
    },
    defaultItem: {
      id: "",
      license_no: "",
      country: "",
      specialist: "",
      type_code: "STR/SIP",
      expired_at: "",
      issued_at: "",
    },
  }),

  computed: {
    form() {
      return {
        name: this.name,
        country: this.country,
        specialist: this.specialist,
      };
    },
    dateFormatted_issue: {
      get: function () {
        return this.formatDate(this.issued_at);
      },
      set: function (newValue) {
        this.issued_at = newValue;
      },
    },
    dateFormatted_expiry: {
      get: function () {
        return this.formatDate(this.expired_at);
      },
      set: function (newValue) {
        this.expired_at = newValue;
      },
    },
    dateFormatted_issue_edit: {
      get: function () {
        return this.formatDate(this.editedItem.issued_at);
      },
      set: function (newValue) {
        this.editedItem.issued_at = newValue;
      },
    },
    dateFormatted_expiry_edit: {
      get: function () {
        return this.formatDate(this.editedItem.expired_at);
      },
      set: function (newValue) {
        this.editedItem.expired_at = newValue;
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    name() {
      this.errorMessages = "";
    },
  },

  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.license_no"),
        align: "center",
        sortable: false,
        value: "license_no",
      },
      {
        text: this.$t("field.country"),
        value: "country",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.specialist"),
        value: "specialist",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.type"),
        value: "type_code",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.issued_at"),
        value: "issued_at",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.expired_at"),
        value: "expired_at",
        sortable: false,
        align: "center",
      },
    ];
    this.initLicense();
  },

  mounted() {
    // country
    API.get(`${process.env.VUE_APP_API_TRUST}country/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.countries = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
  },

  methods: {
    async initLicense() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}license/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        var tempData = [];
        for (const item in res.results) {
          let data = res.results[item];

          let format = {
            id: data.id,
            license_no: data.license,
            country_id: data.country_id,
            country: data.country_name,
            specialist: data.specialist,
            type_code: data.type_code,
            issued_at: moment(data.issued_at)
              .format("DD-MMM-YYYY")
              .toUpperCase(),
            issued_at_iso: moment(data.issued_at).format("YYYY-MM-DD"),
            expired_at: moment(data.expire_at)
              .format("DD-MMM-YYYY")
              .toUpperCase(),
            expired_at_iso: moment(data.expire_at).format("YYYY-MM-DD"),
          };
          tempData.push(format);
        }

        this.datas = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let vm = this;
      try {
        let form = { id: this.datas[this.editedIndex].id };
        (async function () {
          const res = await API.del(
            `${process.env.VUE_APP_API_TRUST}license/delete`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            vm.toast("success", vm.$t("field.data_delete_success"));
            setTimeout(function () {
              vm.initLicense();
            }, 200);
          } else if (res.statusCode == 403) {
            vm.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   vm.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            vm.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
        })();
      } catch (error) {
        this.$swal({
          icon: "error",
          text: vm.$t("error_try_again_latter"),
        });
      }
      this.closeDelete();
    },

    close() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async update() {
      /* eslint-disable no-unused-vars */
      if (this.$refs.form.validate() == true) {
        var form = {
          id: this.editedItem.id,
          license: this.editedItem.license_no,
          sip: this.editedItem.type_code,
          type_code: this.editedItem.type_code,
          country_id: this.editedItem.country_id,
          specialist: this.editedItem.specialist,
          issued_at: this.editedItem.issued_at_iso,
          expire_at: this.editedItem.expired_at_iso,
        };

        var formData = [];
        formData.push(form);

        var _this = this;
        await API.put(
          `${process.env.VUE_APP_API_TRUST}license/update`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          formData
        )
          .then(res => {
            if (res.statusCode == 200 || res.statusCode == 201) {
              this.toast("success", this.$t("field.data_edit_success"));
              _this.initLicense();
              _this.closeEdit();
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
            }
          })
          .catch(er => {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          });
      }
      /* eslint-disable no-unused-vars */
    },

    async save() {
      if (this.$refs.form.validate() == true) {
        var form = {
          license: this.license_no,
          sip: this.type_code,
          type_code: this.type_code,
          country_id: this.country,
          specialist: this.specialist,
          issued_at: this.issued_at,
          expire_at: this.expired_at,
        };

        var formData = [];
        formData.push(form);

        var _this = this;
        await API.post(
          `${process.env.VUE_APP_API_TRUST}license/add`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          formData
        )
          .then(res => {
            if (res.statusCode == 200 || res.statusCode == 201) {
              this.toast("success", this.$t("field.data_add_success"));
              _this.initLicense();
              _this.close();
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
            }
          })
          .catch(er => {
            this.$swal({
              icon: "error",
              text: this.$t("error_try_again_latter"),
            });
          });
      }
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },

    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formatDate(date) {
      // console.log(date);
      if (!date) return null;
      // const MMM = {
      //   "01": "Jan",
      //   "02": "Feb",
      //   "03": "Mar",
      //   "04": "Apr",
      //   "05": "May",
      //   "06": "June",
      //   "07": "July",
      //   "08": "Aug",
      //   "09": "Sept",
      //   "10": "Oct",
      //   "11": "Nov",
      //   "12": "Dec",
      // };
      // const [year, month, day] = date.split("-");
      // let dates = `${day} ${MMM[month]} ${year}`;
      // console.log(day);
      // console.log(year);
      // console.log(month);
      // console.log(dates.toUpperCase());
      // console.log('   ');
      // console.log('   ');
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
  },
};
</script>
<style>
.uppercase-table {
  text-transform: uppercase;
}

.table-license thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-license thead tr th {
  height: 38px !important;
}
.table-license tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
}
.table-license tbody tr:hover {
  background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.v-btn {
  display: flex;
  justify-content: flex-end;
}

.v-data-table td {
  font-size: 12px !important;
}
.text-custom {
  font-size: 12px !important;
}
</style>
